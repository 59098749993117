import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../../contexts/AuthProvider'
import constructRedirectToURL from '../utils/constructRedirectToURL'

const useRedirectIfAuthenticated = () => {
	const { isAuthenticated } = useAuthContext()
	const navigate = useNavigate()
	const location = useLocation()
	/**
	 * Redirect user if already signed in
	 * */
	useEffect(() => {
		if (isAuthenticated) {
			navigate(constructRedirectToURL(location.search), { replace: true })
		}
	}, [isAuthenticated])
}

export default useRedirectIfAuthenticated
