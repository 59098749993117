import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { addFlashMessage } from '../../../../../api/app/behaviors/flash-messages'
import { addTemporaryEvent } from '../../../../../api/app/events'

const useOAuthFailureHandlers = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [searchQuery] = useSearchParams()
	const location = useLocation()

	useEffect(() => {
		const oauthFail = searchQuery.get('oauth_fail')
		const invalidAuthReq = searchQuery.get('invalid_auth_req')
		const tempEvent = searchQuery.get('temp_event')

		if (oauthFail && invalidAuthReq) {
			if (tempEvent) {
				dispatch(addTemporaryEvent(JSON.parse(tempEvent)))
			}
			if (oauthFail === 'true') {
				dispatch(addFlashMessage(`error`, 'Oops something went wrong, please try again'))
			}
			if (oauthFail === 'true' && invalidAuthReq === 'false') {
				dispatch(addFlashMessage(`error`, `Signin method failed, please use a different signin method.`))
			}
			navigate(location.pathname)
		}
	}, [])
}

export default useOAuthFailureHandlers
