import React, { useState } from 'react'
import { AlertIcon, AlertTitle, AlertDescription, Alert, Heading, Container, VStack, Text } from '@chakra-ui/react'
import Card from '../../../atoms/Card'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import useFormState from '../../../../hooks/useFormState'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ResetPasswordForm from './Form'
import schemaResetPassword from './schema'
import useScrollTopOnMount from '../../../../hooks/useScrollTopOnMount'
import { resetPassword } from '../../../../../api/app/auth'

const ResetPassword = () => {
	useScrollTopOnMount()
	const dispatch = useDispatch()

	/** Use params hook */
	const { token } = useParams()

	/**
	 * We need to prevent double submit, so let's check if
	 * we're submitting the form  and pass in email verification
	 * */
	const { status } = useFormState('ResetPassword')

	/** Local State */
	const [sentIndicator, setSentIndicator] = useState(false)

	/** Form Hook */
	const methods = useForm({
		defaultValues: { password: null, confirmation: null },
		resolver: yupResolver(schemaResetPassword),
	})

	/**
	 * Form Submit callback
	 * */
	const onSubmit = (data) => {
		// setSentIndicator(true)
		token && dispatch(resetPassword(token, data))
	}

	return (
		<>
			<FormProvider {...methods}>
				<Container
					// maxW="container.sm"
					py="2rem"
					variant="main"
					maxW="480px">
					<VStack mb="2rem">
						<Text fontSize="3rem">🔐</Text>
						<Heading as="h2" variant="hero" size="lg">
							Reset Password
						</Heading>
						{/* <Text>We will send you a link to reset your password.</Text> */}
					</VStack>

					{sentIndicator && (
						<Alert status="success" borderRadius="lg" mb="1rem" variant="solid">
							<AlertIcon />
							<VStack alignItems="flex-start" spacing="0">
								<AlertTitle>Done!</AlertTitle>
								<AlertDescription>Your password has been reset.</AlertDescription>
							</VStack>
						</Alert>
					)}

					<Card
						px={['1rem', '2rem']}
						py="2rem"
						alignItems="center"
						variant="main"
						mb="2rem"
						textAlign="center"
						mx={['1rem', 0]}
						borderRadius={['xl']}>
						<ResetPasswordForm onSubmit={onSubmit} />
					</Card>
				</Container>
			</FormProvider>
		</>
	)
}

export default ResetPassword
