import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Routes, Route, useSearchParams } from 'react-router-dom'
import Login from './Login'
import Register from './Register'
import { Helmet } from 'react-helmet'
import ActivationRequired from './ActivationRequired'
import useOAuthFailureHandlers from './hooks/useOAuthFailureHandlers'
import useRedirectIfAuthenticated from './hooks/useRedirectIfAuthenticated'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import Activate from './Activate'
import FancyLoader from '../../atoms/FancyLoader'
import MetaTagsDefault from '../../organisms/MetaTagsDefault'

const Root = () => {
	return (
		<>
			<Helmet>
				<title>VidDay - Checking Authentication</title>
			</Helmet>
			<FancyLoader />
		</>
	)
}

const Auth = () => {
	const activationRequired = useSelector((state) => state.auth.activationRequired, shallowEqual)

	/**
	 * Redirect user if already signed in
	 * */
	useRedirectIfAuthenticated()

	/**
	 * Handle OAuth failures messages
	 */
	useOAuthFailureHandlers()

	/**
	 * If account activation is required, force rendering
	 */
	if (activationRequired) {
		return <ActivationRequired />
	}

	/**
	 * Render Main Auth Router
	 */
	return (
		<>
			<MetaTagsDefault />
			<Routes>
				<Route index element={<Root />} />
				<Route path="login" element={<Login />} />
				<Route path="register" element={<Register />} />
				<Route path="forgot" element={<ForgotPassword />} />
				<Route path="reset/:token" element={<ResetPassword />} />
				<Route path="activate/:token" element={<Activate />} />
			</Routes>
		</>
	)
}

export default Auth
