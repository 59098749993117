import React, { useEffect } from 'react'
import { Image, Heading, Text, VStack, Stack, Button, Modal, ModalOverlay, ModalContent } from '@chakra-ui/react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { resendVerificationEmail } from '../../../../../api/app/auth'
import { resetTemporaryUser } from '../../../../../api/app/auth'
import { setActivationRequiredFalse } from '../../../../../api/app/auth'
import { reset } from 'redux-form'
import { Helmet } from 'react-helmet'
import Event from '../../Event'
import useTemporaryEvent from '../../../../hooks/useTemporaryEvent'
import { useSocketContext } from '../../../../contexts/SocketProvider'
import { history } from '../../../../../history'

const ActivationRequired = () => {
	const dispatch = useDispatch()
	const temporaryEvent = useTemporaryEvent()
	const [searchParams, setSearchParams] = useSearchParams()
	const navigate = useNavigate()
	const email = useSelector((s) => s.auth.email, shallowEqual)
	const {
		public: { socket: publicSocket },
	} = useSocketContext()

	const handleResendVerificationEmail = () => {
		if (email) {
			dispatch(resendVerificationEmail(email))
		}
	}

	const handleCancelVerify = () => {
		dispatch(reset('login_form'))
		dispatch(resetTemporaryUser())
		dispatch(setActivationRequiredFalse())
	}

	const handleActivationSuccess = () => {
		// extract `next` from search params, or use default (/dashboard)
		let next = '/dashboard'
		if (searchParams.has('next')) {
			next = searchParams.get('next')
			searchParams.delete('next')
		}
		// remove `activationRequired` from search params
		if (searchParams.has('activation_required')) {
			searchParams.delete('activation_required')
		}
		// update browser history (important do this before reload)
		history.push(`${next}?${searchParams.toString()}`)
		// force full reload for authed session to take effect
		window.location.reload()
	}

	// !!DANGER!!
	// this is a very specific use-case, DO NOT COPY elsewhere
	useEffect(() => {
		publicSocket.emit('join', `email:${email}`)
		publicSocket.on(`activationSuccess`, handleActivationSuccess)
		return () => {
			publicSocket.emit('leave', `email:${email}`)
			publicSocket.off('activationSuccess')
		}
	}, [])

	return (
		<>
			<Helmet>
				<title>VidDay - Activate your account</title>
			</Helmet>

			{temporaryEvent && <Event event={temporaryEvent} />}

			<Modal
				onClose={() => {}}
				isOpen={true}
				scrollBehavior="outside"
				isCentered={['false', 'true']}
				size="lg"
				autoFocus={false}>
				<ModalOverlay bg="rgba(0,0,0,.75)" backdropFilter="auto" backdropBlur="0" />
				<ModalContent bg={'white'} mx="1rem">
					<VStack spacing="1rem" textAlign="center" w="full" mb="2rem">
						<Image src="/assets/images/graphics/email_illustration.svg" alt="VidDay Email" w="120px" />
						<Heading as="h2" size="lg" variant="hero">
							Activate your account.
						</Heading>
						<Text>We sent you an email to verify your email address — please check your inbox at:</Text>
						<Text fontWeight="bold">{email}</Text>
					</VStack>
					<Stack w="full" direction={['column', 'row']} spacing="1rem" justifyContent="center">
						<Button variant="ghost" onClick={handleCancelVerify}>
							Not Your Email?
						</Button>
						<Button variant="solid" onClick={handleResendVerificationEmail}>
							Resend Email
						</Button>
					</Stack>
				</ModalContent>
			</Modal>
		</>
	)
}

export default ActivationRequired
