import * as Yup from 'yup'

export const schemaEmailAndPassword = Yup.object().shape({
	email: Yup.string()
		.email('Please provide a valid email address')
		.required('Please enter your email.')
		.trim('Please remove all spaces.'),
	password: Yup.string().nullable().required('Please enter a password.'),
})

export const schemaEmail = Yup.object().shape({
	email: Yup.string()
		.email('Please provide a valid email address')
		.required('Please enter your email.')
		.trim('Please remove all spaces.'),
})
