export const shortenEmail = (email) => {
	const cutAt = 20
	const prefix = email.split('@')

	if (prefix[0].length >= cutAt + 3) {
		let newPrefix = prefix[0].substring(0, cutAt)
		return `${newPrefix}...@${prefix[1]}`
	}
	return email
}
