import React, { useEffect, useState } from 'react'
import { chakra, Box, Button, HStack, VStack, Text, Link } from '@chakra-ui/react'
import SimpleEmail from '../../../../molecules/controls/InputEmail/SimpleEmail'

import Password from '../../../../molecules/controls/Password'
import { useFormContext } from 'react-hook-form'
import useTemporaryUser from '../../../../../hooks/useTemporaryUser'
import { useDispatch } from 'react-redux'
import { resetTemporaryUser } from '../../../../../../api/app/auth'
import { useSearchParams } from 'react-router-dom'
import useAuthHandlers from '../../hooks/useAuthHandlers'
import { Link as RouterLink } from 'react-router-dom'
import { useAuthContext } from '../../../../../contexts/AuthProvider'

const LoginForm = ({ onSubmit, isSubmitting, ...rest }) => {
	const dispatch = useDispatch()
	const [search] = useSearchParams()
	const { handleCheckAccount } = useAuthHandlers()
	const tempUser = useTemporaryUser()
	const authUser = useAuthContext()

	const { control, handleSubmit, errors, setValue, unregister, formState, watch, setError, clearErrors } =
		useFormContext()

	/** Watch values of the form */
	const values = watch()

	useEffect(() => {
		/** Get email from url if any */
		if (search.has('email')) {
			const urlEncodedEmail = search.get('email')
			const urlEmail = decodeURIComponent(urlEncodedEmail).toLowerCase()
			setValue('email', urlEmail)
			handleCheckAccount(urlEmail)
		} else if (tempUser.email) {
			setValue('email', String(tempUser.email).toLowerCase())
		}
	}, [])

	/** If we've entered an email address the  the sign in form for an account that does not exist,
	 * retrieve the value and save it as default email address */
	useEffect(() => {
		if (tempUser.email) setValue('email', String(tempUser.email).toLowerCase())
		if (authUser.email) setValue('email', String(authUser.email).toLowerCase())
	}, [tempUser.email])

	/** Clear temporary user if email is removed */
	// useEffect(() => {
	// 	if (values.email == '' && formState.isDirty) {
	// 		dispatch(resetTemporaryUser())
	// 	}
	// }, [values.email])

	return (
		<chakra.form w="full" onSubmit={handleSubmit(onSubmit)} {...rest}>
			<Box w="full">
				<VStack spacing="1rem" mb="1rem">
					<SimpleEmail control={control} errors={errors} name="email" placeholder="Email" />

					<Password control={control} errors={errors} name="password" placeholder="Password" />

					<Link to="/auth/forgot" as={RouterLink} color="link">
						Forgot your password?
					</Link>
				</VStack>
			</Box>

			<HStack w="full" justify="center">
				<Button type="submit" variant="solid" minW="150px" isLoading={isSubmitting}>
					<span>Log In</span>
				</Button>
			</HStack>
		</chakra.form>
	)
}

export default LoginForm
