import * as Yup from 'yup'
const namePattern = /^(?!.*\/|.*\\|.*@|.*\.[^\s]).*$/
export const schema = Yup.object().shape({
	email: Yup.string()
		.email('Please provide a valid email address')
		.required('Please enter your email address.')
		.trim('Please remove all spaces.'),
	firstName: Yup.string().when('email', {
		is: (email) => email !== '',
		then: Yup.string().required('Please provide a first name.'),
		otherwise: Yup.string()
			.max(32, 'First name cannot exceed 32 characters.')
			.matches(namePattern, 'First name cannot contain any links or URL patterns.')
			.notRequired()
			.default(''),
	}),
	lastName: Yup.string().when('email', {
		is: (email) => email !== '',
		then: Yup.string().required('Please provide a last name.'),
		otherwise: Yup.string()
			.max(32, 'First name cannot exceed 32 characters.')
			.matches(namePattern, 'First name cannot contain any links or URL patterns.')
			.notRequired()
			.default(''),
	}),
	password: Yup.string()
		.required('Please enter a new password.')
		.min(8, 'Password must be at least 8 characters long.'),
	passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match.'),
	// notifications: Yup.object().shape({
	// 	promos: Yup.boolean().oneOf([true, false], 'This field is required').required('This field is required'),
	// }),
})

export default schema
