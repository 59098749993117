import { useLocation } from 'react-router-dom'
import useUserLocation from '../../../../hooks/useUserLocation'
import useTemporaryEvent from '../../../../hooks/useTemporaryEvent'
import constructRedirectToURL from '../utils/constructRedirectToURL'

const useOAuthSubmit = () => {
	const location = useLocation()
	const temporaryEvent = useTemporaryEvent()
	const userLocation = useUserLocation()

	const handleOauthSubmit = (e) => {
		const pathname = e?.view?.location?.pathname
		const service = e.currentTarget.dataset.service
		const href = buildServiceLogin(service, pathname)

		e.currentTarget.lastChild.textContent = 'Please Wait...'
		e.currentTarget.classList.add('disabled')
		e.currentTarget.setAttribute('disabled', 'disabled')
		e.target.href = href
	}

	const buildServiceLogin = (service, pathname) => {
		var url = '/api/v2/auth/' + service

		// Determine redirect url after oauth success
		var dirtyReturnTo = `?return_to=${constructRedirectToURL(location.search)}`
		// dirtyReturnTo might contain two '?', so clean this up
		var returnTo = `?${dirtyReturnTo.split('?').join('')}`

		// Set failureRedirect url
		var failureRedirect = `&failure_redirect=${pathname}`
		// Determine if oauth callback include tempEvent
		var temporaryEventEncoded = temporaryEvent
			? '&temp_event=' + encodeURIComponent(JSON.stringify(temporaryEvent))
			: ''

		// Determine if oauth callback includes location information
		var locationEncode = userLocation ? '&user_location=' + encodeURIComponent(JSON.stringify(userLocation)) : ''

		return url.concat(returnTo, failureRedirect, temporaryEventEncoded, locationEncode).toString()
	}

	return handleOauthSubmit
}

export default useOAuthSubmit
