import React, { useState } from 'react'
import { AlertIcon, AlertTitle, AlertDescription, Alert, Heading, Container, VStack, Text } from '@chakra-ui/react'
import Card from '../../../atoms/Card'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import ForgotPasswordForm from './Form'
import { schemaEmail } from './schema'
import useScrollTopOnMount from '../../../../hooks/useScrollTopOnMount'
import { forgotPassword } from '../../../../../api/app/auth'
import { reset as resetPasswordForm } from '../../../../../reducers/forms/forgot-password'
import { Helmet } from 'react-helmet'
import { useTemporaryUserContext } from '../../../../contexts/TemporaryUserProvider'

const ForgotPassword = () => {
	useScrollTopOnMount()
	const dispatch = useDispatch()

	const account = useTemporaryUserContext()

	/** Form Hook */
	const methods = useForm({
		defaultValues: {
			email: null,
		},
		resolver: yupResolver(schemaEmail),
	})

	const [isSubmitting, setIsSubmitting] = useState(false)

	const { clearErrors, reset, formState, setError } = methods

	/**
	 * Form Submit callback
	 * */
	const onSubmit = async (data) => {
		setIsSubmitting(true)

		/**  WITH EMAIL VERIFICATION (disabled for now) */
		// // Clean email value
		// const inputValue = data.email.trim().toLowerCase()
		// // Verify email entered by user
		// const res = await verifyEmail(inputValue)
		// // If the email isnt valid
		// if (!res.isValid) {
		// 	setError('email', {
		// 		type: 'error',
		// 		message: 'Invalid email, please check your entry.',
		// 	})
		// 	setIsSubmitting(false)
		// }
		// // Otherwise proceed with form submission
		// else {
		// 	// Reset password
		// 	dispatch(forgotPassword(data))
		// 	// Reset the form errors
		// 	clearErrors()
		// 	// Reset the form state/value
		// 	reset()
		// 	// Reset form in redux (this will disappear in the future)
		// 	dispatch(resetPasswordForm())
		// 	// Finally, reset submitting state
		// 	setIsSubmitting(false)
		// }
		/**  end of WITH EMAIL VERIFICATION (disabled for now) */

		// Reset password
		dispatch(forgotPassword(data))
		// Reset the form errors
		clearErrors()
		// Reset the form state/value
		reset()
		// Reset form in redux (this will disappear in the future)
		dispatch(resetPasswordForm())
		// Finally, reset submitting state
		setIsSubmitting(false)
	}

	return (
		<>
			<Helmet>
				<title>VidDay - Forgot your password</title>
			</Helmet>
			<FormProvider {...methods}>
				<Container
					// maxW="container.sm"
					py="2rem"
					variant="main"
					maxW="480px">
					<VStack mb="2rem">
						<Text fontSize="3rem">🔐</Text>
						<Heading as="h2" variant="hero" size="lg">
							Forgot your password?
						</Heading>
						<Text>We will send you a link to reset your password.</Text>
					</VStack>

					{formState.isSubmitSuccessful && (
						<Alert status="success" borderRadius="lg" mb="1rem" variant="solid">
							<AlertIcon />
							<VStack alignItems="flex-start" spacing="0">
								<AlertTitle>Sent!</AlertTitle>
								<AlertDescription>
									Your password reset link has been sent — check your inbox or junk mail.
								</AlertDescription>
							</VStack>
						</Alert>
					)}

					<Card
						px={['1rem', '2rem']}
						py="2rem"
						alignItems="center"
						variant="main"
						mb="2rem"
						textAlign="center"
						mx={['1rem', 0]}
						borderRadius={['xl']}>
						<ForgotPasswordForm onSubmit={onSubmit} isSubmitting={isSubmitting} />
					</Card>
				</Container>
			</FormProvider>
		</>
	)
}

export default ForgotPassword
