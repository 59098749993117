import React, { useEffect } from 'react'
import { chakra, Box, Button, HStack, VStack, Link, Flex } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { resetTemporaryUser } from '../../../../../../api/app/auth'
import { Link as RouterLink } from 'react-router-dom'
import { useTemporaryUserContext } from '../../../../../contexts/TemporaryUserProvider'
import SimpleEmail from '../../../../molecules/controls/InputEmail/SimpleEmail'

const LoginForm = ({ onSubmit, isSubmitting }) => {
	const dispatch = useDispatch()

	const account = useTemporaryUserContext()

	const { control, handleSubmit, errors, setValue, unregister, formState, watch, setError, clearErrors } =
		useFormContext()

	/** Watch values of the form */
	const values = watch()

	/** Update default value */
	useEffect(() => {
		if (account) setValue('email', account.email)
	}, [])

	/** Check and clear temporary user if email is removed */
	useEffect(() => {
		if (values.email == '' && formState.isDirty) {
			dispatch(resetTemporaryUser())
		}
	}, [values.email])

	return (
		<chakra.form w="full" onSubmit={handleSubmit(onSubmit)}>
			{!formState.isSubmitSuccessful && (
				<>
					<Box w="full">
						<VStack spacing="1rem" mb="2rem">
							<SimpleEmail control={control} errors={errors} name="email" placeholder="Email" />
						</VStack>
					</Box>

					<HStack w="full" justify="center" mb="2rem">
						<Button type="submit" variant="solid" minW="150px" isLoading={isSubmitting}>
							<span>Send Reset Link</span>
						</Button>
					</HStack>
				</>
			)}

			<Flex w="full" justify="center">
				<Link href="/auth/login" as="a" fontWeight="bold" color="link">
					Back to Login
				</Link>
			</Flex>
		</chakra.form>
	)
}

export default LoginForm
