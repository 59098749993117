import React from 'react'
import { Heading, VStack, Text } from '@chakra-ui/react'
import { getRecipients } from '@vidday/utils'
import useTemporaryEvent from '../../../../../hooks/useTemporaryEvent'
import useTemporaryUser from '../../../../../hooks/useTemporaryUser'

const HeaderNewUser = ({ ...rest }) => {
	return (
		<VStack {...rest}>
			<Text fontSize="3rem">👋</Text>
			<Heading as="h2" variant="hero" size="lg">
				Hello, you're new here.
			</Heading>
			<Text>Create a free account in order to collect and manage videos and photos.</Text>
		</VStack>
	)
}

const HeaderCreatePassword = ({ ...rest }) => {
	return (
		<VStack {...rest}>
			<Text fontSize="3rem">👋</Text>
			<Heading as="h2" variant="hero" size="lg">
				Please create a password.
			</Heading>
			<Text>Looks like you've used VidDay before, choose an option or create a password.</Text>
		</VStack>
	)
}

const HeaderTemporaryEvent = ({ ...rest }) => {
	const temporaryEvent = useTemporaryEvent()
	const recipients = getRecipients(temporaryEvent?.recipients, true).names
	const occasionEmoji = temporaryEvent?.occasion == 'memorial' ? '✅' : '🎉'

	return (
		<VStack {...rest} mx="-1rem">
			<Text fontSize="3rem">{occasionEmoji}</Text>
			<Heading as="h2" variant="hero" fontSize="var(--vidday-fontSizes-3xl)">
				You're all set!
			</Heading>
			<Text fontSize={['1.125rem', '1.25rem']}>Create an account to save your progress.</Text>
		</VStack>
	)
}

const HeaderDefault = ({ ...rest }) => {
	const occasionEmoji = ''
	const defaultEmoji = ''
	return (
		<VStack {...rest} textAlign="center">
			<Text fontSize="3rem">{occasionEmoji || defaultEmoji}</Text>
			<Heading as="h2" variant="hero" size="lg">
				Create an Account
			</Heading>
		</VStack>
	)
}

const Header = ({ ...rest }) => {
	const { account, success } = useTemporaryUser()
	const temporaryEvent = useTemporaryEvent()

	if (temporaryEvent) {
		return <HeaderTemporaryEvent {...rest} />
	}
	if (success) {
		return <HeaderNewUser {...rest} />
	}
	if (account && !account.isActive) {
		return <HeaderCreatePassword {...rest} />
	}
	return <HeaderDefault {...rest} />
}

export default Header
