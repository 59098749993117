import React, { useState } from 'react'
import { FormErrorMessage } from '@chakra-ui/react'
import { Controller } from 'react-hook-form'
import { FormLabel, InputGroup, FormHelperText, FormControl, Input, InputRightElement, Icon } from '@chakra-ui/react'
import PasswordStrength from './PasswordStrength'
import { VisibilityRounded, VisibilityOffRounded } from '../../../atoms/Icon'

const ControlledPassword = (props) => {
	const {
		includestrength,
		rightElement,
		control,
		name,
		label,
		placeholder,
		helperText,
		errors,
		defaultValue,
		index,
		readOnly,
	} = props

	const { setValue, getValues } = control

	/** Hook to set password visibility */
	const [isvisible, setisvisible] = useState(props.isvisible)
	/** Method to Toggle Visibility of the password */
	const toggleVisibility = () => setisvisible(isvisible === true ? false : true)

	/**
	 * If we have provided an index, then it means we're using a FieldArray
	 */
	let isError = false
	let errorMessage = ''

	// Otherwise, proceed as a normal (non-nested) input
	if (errors && errors.hasOwnProperty(name) === true) {
		isError = true
		errorMessage = errors[name].message
	}

	return (
		<Controller
			name={name}
			control={control}
			render={(props, { invalid, isTouched, isDirty }) => {
				return (
					<FormControl isInvalid={isError}>
						{label && <FormLabel htmlFor={name}>{label}</FormLabel>}
						<InputGroup>
							<Input
								autoComplete="on"
								type={isvisible ? 'input' : 'password'}
								disabled={readOnly}
								id={name}
								placeholder={placeholder}
								{...props}
							/>
							<InputRightElement
								title={isvisible ? 'Hide password' : 'Show password'}
								aria-label="toggle password visibility"
								onClick={toggleVisibility}
								children={
									isvisible ? (
										<VisibilityOffRounded color="gray.300" />
									) : (
										<VisibilityRounded color="gray.300" />
									)
								}
							/>
						</InputGroup>
						{isError ? (
							<FormErrorMessage display="block" textAlign="left">
								{errorMessage}
							</FormErrorMessage>
						) : (
							helperText && <FormHelperText>{helperText}</FormHelperText>
						)}
						{includestrength && <PasswordStrength value={props.value} />}
					</FormControl>
				)
			}}
		/>
	)
}

export default ControlledPassword
