import React, { useState, useEffect } from 'react'
import { chakra, Box } from '@chakra-ui/react'
import { LockRounded } from '../../../../atoms/Icon'

const wrapperStyles = {
	h: '25px',
	pos: 'relative',
	my: '0.25rem',
	textAlign: 'left',
}

const strengthStyles = {
	'pos': 'absolute',
	'left': 0,
	'width': '0%',
	'h': '5px',
	'fontSize': '0.75rem',
	'mt': '11px',
	'ml': '20px',
	'background': 'transparent',
	'borderRadius': '6px',
	'transition': 'all 175ms ease-in 0ms',
	'_after': {
		pos: 'absolute',
		display: 'block',
		w: '60px',
		left: '102%',
		top: '-7px',
		fontSize: ['0.65rem', '0.75rem'],
		fontWeight: 'bold',
	},

	'&[data-score="0"]': {
		'width': 'calc(30% - 60px)',
		'backgroundColor': '#FF1C63',
		'&::after': {
			content: '"Too short"',
		},
	},

	'&[data-score="1"]': {
		'width': 'calc(45% - 60px)',
		'backgroundColor': '#FF6B24',
		'&::after': {
			content: '"Too short"',
		},
	},

	'&[data-score="2"]': {
		'width': 'calc(65% - 60px)',
		'backgroundColor': '#FFAF2F',
		'&::after': {
			content: '"Too short"',
		},
	},
	'&[data-score="3"]': {
		'width': 'calc(85% - 60px)',
		'backgroundColor': '#04D898',
		'&::after': {
			content: '"Good"',
		},
	},
	'&[data-score="4"]': {
		'width': 'calc(100% - 70px)',
		'backgroundColor': '#04D898',
		'color': '#04D898',

		'&::after': {
			content: '"Great!"',
		},
	},

	'&:not([data-score="null"]) span': {
		display: 'none',
	},
}

const iconStyles = {
	pos: 'absolute',
	left: 0,
	top: '5px',
	transition: '0.05s',
}

/* ****************************
 * PASSWORD STRENGH COMPONENT
 * ************************** */

const PasswordStrengthWrap = (props) => {
	/** Retrieve props */
	const { value, theme } = props
	/** Hold score into the state */
	const [score, setScore] = useState(null) // Keep score as string

	/** Check password strength when field value changes if includestrength is true */
	useEffect(() => {
		if (value === null) {
			setScore(null)
		} else {
			// map the length of the string to a score between 1 and 4
			let score = value?.length < 3 ? 0 : value?.length < 6 ? 1 : value?.length < 8 ? 2 : 4
			setScore(score)
		}
	}, [value])

	let color
	if (score == null) {
		color = 'inherit'
	} else if (score == 0) {
		color = '#FF1C63'
	} else if (score == 1) {
		color = '#FF6B24'
	} else if (score == 2) {
		color = '#FFAF2F'
	} else if (score >= 3) {
		color = '#04D898'
	}

	return (
		<Box sx={wrapperStyles} theme={theme}>
			{value && (
				<>
					<LockRounded
						sx={iconStyles}
						alt="Lock Icon"
						boxSize="16px"
						color={color}
						h="1rem"
						w="1rem"

						// filter={
						// 	score == 4
						// 		? 'brightness(0) saturate(100%) invert(66%) sepia(53%) saturate(1620%) hue-rotate(171deg) brightness(98%) contrast(106%) !important'
						// 		: 'brightness(0.8)'
						// }
					/>

					<Box sx={strengthStyles} data-score={score}>
						<chakra.span pos="absolute" mt="-6px">
							<strong>A strong password</strong> is at least 8 characters long.
						</chakra.span>
					</Box>
				</>
			)}
		</Box>
	)
}

/** Define default props (!important) */
PasswordStrengthWrap.defaultProps = {
	value: null,
}

export default PasswordStrengthWrap
