import React, { useEffect, useState } from 'react'
import { Alert, Heading, Box, Button, VStack, Text, Link, Flex, Stack, Divider } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getRecipients } from '@vidday/utils'
import useTemporaryEvent from '../../../../../hooks/useTemporaryEvent'
import useTemporaryUser from '../../../../../hooks/useTemporaryUser'
import { useDispatch } from 'react-redux'
import { resetTemporaryUser } from '../../../../../../api/app/auth'
import { Link as RouterLink } from 'react-router-dom'
import redirectToNextIfWindow from '../../../../../utils/redirectToNextIfWindow'

const ContributeHeader = ({ ...rest }) => {
	const [searchQuery] = useSearchParams()
	const sanitize = (s) => s.get('next')?.replace('/event', '/e')

	const [link, setLink] = useState(null)

	useEffect(() => {
		// only do this in browser (no ssr)
		if (typeof window !== 'undefined') {
			/** Promp contributor if uuid is wanted */
			if (searchQuery.has('next')) {
				const next = searchQuery.get('next')

				if (next && next.includes('/event/' || next.includes('/e/'))) {
					setLink(sanitize(searchQuery))
				}
			}
		}
	}, [])

	return (
		<Flex direction="column" alignItems="center" w="full" {...rest}>
			{!link && (
				<Box w="full">
					<Text fontSize="3rem">🥳</Text>
					<Heading as="h2" variant="hero" size="lg">
						Log In
					</Heading>
				</Box>
			)}
			{link && (
				<Box w="full" px={['1rem', 0]}>
					<Alert status="info" variant="solid" mb="2rem" borderRadius="lg">
						<Stack w="full" justify="space-between" alignItems="center" direction={['column', 'row']}>
							<Box textAlign={['center', 'left']}>
								<Text fontWeight="bold" color="white">
									Did someone invite you?
								</Text>
								<Text color="white">No need to log in to contribute.</Text>
							</Box>
							<Button
								as="a"
								// to="/auth/login"
								href={link}
								variant="outline"
								colorScheme="whiteAlpha"
								color="white"
								size="sm">
								Contribute Now
							</Button>
						</Stack>
					</Alert>
				</Box>
			)}
			{link && <Divider borderStyle="dashed" borderColor="gray.300" mb="2rem" />}
		</Flex>
	)
}

const Header = ({ ...rest }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { account, pollingUserData, email } = useTemporaryUser()
	const temporaryEvent = useTemporaryEvent()

	const { watch } = useFormContext()

	const values = watch()

	const handleResetTemporaryUser = () => {
		dispatch(resetTemporaryUser())
		redirectToNextIfWindow('/auth/login')
		// navigate('/auth/login')
	}

	/**
	 * Return Header based on existing Account or not
	 * */
	if (temporaryEvent) {
		return (
			<VStack mb="1rem" {...rest}>
				<Text fontSize="3rem">{temporaryEvent?.occasion == 'memorial' ? '' : '🎉'}</Text>
				<Heading as="h2" variant="hero" fontSize="var(--vidday-fontSizes-3xl)">
					You're all set!
				</Heading>
				<Text fontSize={['1.125rem', '1.25rem']}>Log in to your account to create your video.</Text>
				{account && email && (
					<>
						<Button variant="link" onClick={() => handleResetTemporaryUser()}>
							Not your email?
						</Button>
					</>
				)}
			</VStack>
		)
	} else if (account) {
		if (account.isRegistered && values.email == email) {
			if (account.isActive) {
				return (
					<VStack mb="1rem" {...rest}>
						<Text fontSize="3rem">👋</Text>
						<Heading as="h2" variant="hero" size="lg">
							Welcome back!
						</Heading>
						<Text>
							Not your email?{' '}
							<Link as="a" href="/auth/login" variant="link" onClick={handleResetTemporaryUser}>
								Try another
							</Link>
						</Text>
					</VStack>
				)
			} else {
				return (
					<VStack mb="1rem" {...rest}>
						<Text fontSize="3rem">👋</Text>
						<Heading as="h2" variant="hero" size="lg">
							Welcome back!
						</Heading>
						<Text>
							Looks like you still need to activate your account for <strong>{email}</strong>
						</Text>
						<Link as="a" href="/auth/login" variant="link" onClick={handleResetTemporaryUser}>
							Not your email?
						</Link>
					</VStack>
				)
			}
		}
	} else if (!account) {
		return <ContributeHeader {...rest} />
	}

	if (temporaryEvent) {
		const recipients = getRecipients(temporaryEvent.recipients, true).names

		return (
			<VStack mb="1rem" {...rest}>
				<Text fontSize="3rem">✅</Text>
				<Heading as="h2" variant="hero" size="lg">
					Your event page is set up!
				</Heading>
				<Text>
					Log in to your account to securely collect videos and photos
					{temporaryEvent?.isPersonal ? '.' : ` for ${recipients}.`}{' '}
				</Text>
				{account && email && (
					<>
						<Button variant="link" onClick={() => handleResetTemporaryUser()}>
							Not your email?
						</Button>
					</>
				)}
			</VStack>
		)
	}

	return (
		<VStack mb="1rem" {...rest}>
			<Text fontSize="3rem">🥳</Text>
			<Heading as="h2" variant="hero" size="lg">
				Log in
			</Heading>
		</VStack>
	)
}

export default Header
