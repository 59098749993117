import qs from 'qs'

const constructRedirectToURL = (searchParamString) => {
	// default redirect, should come from app settings
	var redirectTo = '/dashboard'

	// Get search params object from react router location
	var searchParams = qs.parse(searchParamString, {
		// parameterLimit: 20,
		ignoreQueryPrefix: true,
	})

	// If no search params, return defaul redirect
	if (!searchParams) {
		return redirectTo
	}

	// If 'next' arg is present, redirect url and remove from QSs
	if (searchParams.next) {
		redirectTo = searchParams.next
		delete searchParams.next
	}
	// Cosntruct redirect url with extra query parameters
	return `${redirectTo}?${qs.stringify(searchParams)}`
}

export default constructRedirectToURL
