import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import useAuthHandlers from '../hooks/useAuthHandlers'
import { activateAccount } from '../../../../../api/app/auth'
import { Container, Box, Text, VStack, Heading, Link, Image } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { useAuthContext } from '../../../../contexts/AuthProvider'
import CheckExMark from '../../../atoms/CheckExMark'
import { Helmet } from 'react-helmet'
import Card from '../../../atoms/Card'
import { clearTemporaryEvent } from '../../../../../api/app/events'

const Activate = () => {
	const dispatch = useDispatch()
	const { token } = useParams()
	const { email, isValidToken, isActive } = useAuthContext()
	const { handleResendVerificationEmail } = useAuthHandlers()
	const [activated, setActivated] = useState(isActive)

	useEffect(() => {
		dispatch(activateAccount(token))
		dispatch(clearTemporaryEvent())
		if (isValidToken == false) {
			handleResendVerificationEmail(email)
		}
		// fake the activate
		setTimeout(() => {
			setActivated(true)
		}, 2000)
	}, [])

	return (
		<>
			<Helmet>
				<title>VidDay - Activate your account</title>
			</Helmet>
			<Container py="2rem" variant="main" maxW="480px">
				<Card
					px={['1rem', '2rem']}
					py="2rem"
					alignItems="center"
					justifyContent="center"
					variant="main"
					mb="2rem"
					minH="300px"
					textAlign="center"
					mx={['1rem', 0]}
					borderRadius={['xl']}>
					{isValidToken != null && isValidToken == false ? (
						<>
							<Image
								mx="auto"
								src="https://static.vidday.com/graphics/email_illustration.svg"
								width="80px"
								mb="2rem"
							/>
							<VStack textAlign="center" mb="1rem">
								{email && isValidToken == false ? (
									<>
										<Heading as="h2" variant="hero" size="lg">
											Déjà-vu, you've done this already.
										</Heading>
										<Text>
											Your account for <strong>{email}</strong> has already been activated.
										</Text>
									</>
								) : (
									<>
										<Heading as="h2" variant="hero" size="lg">
											Uh oh, your activation link has expired.
										</Heading>
										<Text>Let's get this sorted, try to log in again.</Text>
									</>
								)}

								<Link href="/auth/login" as="a" variant="link">
									Login
								</Link>
							</VStack>
						</>
					) : (
						<>
							{!activated ? (
								<VStack textAlign="center" mb="1rem">
									<Heading as="h2" variant="hero" size="lg">
										Activating your account...
									</Heading>
								</VStack>
							) : (
								<>
									<Box mx="auto" mb="1rem">
										<CheckExMark render={true} check={true} scale={1.1} />
									</Box>
									<VStack textAlign="center" mb="1rem">
										<Heading as="h2" variant="hero" size="lg">
											Your account has been activated.
										</Heading>
									</VStack>
								</>
							)}
						</>
					)}
				</Card>
			</Container>
		</>
	)
}

export default Activate
