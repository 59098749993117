import React from 'react'
import { chakra, Button, Flex } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import { shallowEqual, useSelector } from 'react-redux'
import useOAuthSubmit from '../../hooks/useOAuthSubmit'

const GoogleButton = ({ label }) => {
	const { formState } = useFormContext()
	const onSubmit = useOAuthSubmit()
	const isSubmitting = useSelector((state) => state.auth.isAuthenticating, shallowEqual)

	const buttonText = `${label} with Google`
	return (
		<Button
			as="a"
			// size="large"
			data-service="google"
			onClick={onSubmit}
			disabled={isSubmitting || formState.isSubmitting}
			variant="solid"
			boxShadow="base"
			bg="white"
			color="link"
			w="full"
			minW="201px"
			_hover={{ bg: 'gray.50', cursor: 'pointer' }}
			_active={{ bg: 'gray.50' }}>
			<Flex justifyContent={'space-between'} alignItems={'center'}>
				<chakra.svg
					mr="0.5rem"
					xmlns="http://www.w3.org/2000/svg"
					width="1.5rem"
					height="1.5rem"
					viewBox="0 0 24 24">
					<g id="Group_22922" data-name="Group 22922" transform="translate(-59.17 -354.441)">
						<path
							id="Path_24400"
							data-name="Path 24400"
							d="M353.333,459.743l-.838,3.127-3.061.065a12.054,12.054,0,0,1-.089-11.236h0l2.726.5,1.194,2.709a7.18,7.18,0,0,0,.067,4.835Zm0,0"
							transform="translate(-288.83 -90.792)"
							fill="#fbbb00"
						/>
						<path
							id="Path_24401"
							data-name="Path 24401"
							d="M555.781,504.133a12.027,12.027,0,0,1-4.289,11.631h0l-3.433-.175-.486-3.033a7.171,7.171,0,0,0,3.086-3.662h-6.434v-4.76Zm0,0"
							transform="translate(-472.821 -139.972)"
							fill="#518ef8"
						/>
						<path
							id="Path_24402"
							data-name="Path 24402"
							d="M389.014,586.931h0a12.036,12.036,0,0,1-18.131-3.681l3.9-3.192a7.156,7.156,0,0,0,10.312,3.664Zm0,0"
							transform="translate(-310.279 -211.14)"
							fill="#28b446"
						/>
						<path
							id="Path_24403"
							data-name="Path 24403"
							d="M387.837,350.77l-3.9,3.191a7.155,7.155,0,0,0-10.549,3.746l-3.92-3.209h0a12.035,12.035,0,0,1,18.368-3.729Zm0,0"
							transform="translate(-308.953 6.441)"
							fill="#f14336"
						/>
					</g>
				</chakra.svg>
				{buttonText}
			</Flex>
		</Button>
	)
}

GoogleButton.defaultProps = {
	label: 'Log In',
}

export default GoogleButton
