import React, { useEffect } from 'react'
import { chakra, Box, Button, HStack, VStack, Link, Flex } from '@chakra-ui/react'
import Password from '../../../../molecules/controls/Password'
import { useFormContext } from 'react-hook-form'
import useFormState from '../../../../../hooks/useFormState'
import { useDispatch } from 'react-redux'
import { resetTemporaryUser } from '../../../../../../api/app/auth'
import useAuthHandlers from '../../hooks/useAuthHandlers'
import { Link as RouterLink } from 'react-router-dom'
import { useTemporaryUserContext } from '../../../../../contexts/TemporaryUserProvider'

const ResetPassword = ({ onSubmit }) => {
	const dispatch = useDispatch()
	/** We need to prevent double submit, so let's check if we're submitting the form  and pass in email verification */
	const formSubState = useFormState('Auth')
	const { status } = formSubState
	const email = status?.data

	const account = useTemporaryUserContext()
	const { handleCheckAccount } = useAuthHandlers()

	const { control, handleSubmit, errors, setValue, unregister, formState, watch, setError, clearErrors } =
		useFormContext()

	/** Watch values of the form */
	const values = watch()

	/**
	 * Reset Temporary User
	 */
	const handleResetTemporaryUser = () => {
		setValue('email', '')
		dispatch(resetTemporaryUser())
	}

	/** Update default value */
	useEffect(() => {
		if (email) setValue('email', email)
		if (account) setValue('email', account.email)
	}, [])

	/** Check and clear temporary user if email is removed */
	useEffect(() => {
		if (values.email == '' && formState.isDirty) {
			dispatch(resetTemporaryUser())
		} else {
			handleCheckAccount(values.email)
		}
	}, [values.email])

	return (
		<chakra.form w="full" onSubmit={handleSubmit(onSubmit)}>
			<>
				<Box w="full">
					<VStack spacing="1rem" mb="2rem">
						<Password
							control={control}
							errors={errors}
							name="password"
							// label="Password"
							placeholder="New Password"
							includestrength={true}
						/>

						<Password
							control={control}
							errors={errors}
							name="confirmation"
							// label="Password"
							placeholder="Confirmation"
						/>
					</VStack>
				</Box>

				<HStack w="full" justify="center" mb="2rem">
					<Button
						type="submit"
						variant="solid"
						minW="150px"
						disabled={
							status.isFetching ||
							formState.isSubmitting ||
							(!status.error && formState.isSubmitSuccessful)
						}
						isLoading={
							status.isFetching ||
							formState.isSubmitting ||
							(!status.error && formState.isSubmitSuccessful)
						}>
						<span>Save Password</span>
					</Button>
				</HStack>
			</>
		</chakra.form>
	)
}

export default ResetPassword
