import React, { useEffect, useState } from 'react'
import { chakra, Box, Button, HStack, VStack, Text, Link, SimpleGrid } from '@chakra-ui/react'
import SimpleEmail from '../../../../molecules/controls/InputEmail/SimpleEmail'
import Password from '../../../../molecules/controls/Password'
import Input from '../../../../molecules/controls/Input'
import Checkbox from '../../../../molecules/controls/Checkbox'
import { useFormContext } from 'react-hook-form'
import useTemporaryUser from '../../../../../hooks/useTemporaryUser'
import { useDispatch } from 'react-redux'
import { resetTemporaryUser } from '../../../../../../api/app/auth'
import { useSearchParams } from 'react-router-dom'
import useAuthHandlers from '../../hooks/useAuthHandlers'
import useUserLocation from '../../../../../hooks/useUserLocation'
import useTemporaryEvent from '../../../../../hooks/useTemporaryEvent'
import { useTemporaryUserContext } from '../../../../../contexts/TemporaryUserProvider'

const RegisterForm = ({ onSubmit, isSubmitting }) => {
	const dispatch = useDispatch()
	const userLocation = useUserLocation()
	const { handleCheckAccount } = useAuthHandlers()
	const temporaryEvent = useTemporaryEvent()

	const account = useTemporaryUserContext()
	const [search] = useSearchParams()

	const { control, handleSubmit, errors, setValue, formState, watch } = useFormContext()

	const {
		email,
		// , pollingUserData
	} = useTemporaryUser()

	/** Watch values of the form */
	const values = watch()

	/** If we've entered an email address the  the sign in form for an account that does not exist,
	 * retrieve the value and save it as default email address */
	useEffect(() => {
		if (email) setValue('email', String(email).toLowerCase())
	}, [])

	/** If no email available for temporary user, and there is one present in search params, use
	 * it to populate email field in the form */
	useEffect(() => {
		if (search.has('email')) {
			const urlEncodedEmail = search.get('email')
			const email = decodeURIComponent(urlEncodedEmail).toLowerCase()
			setValue('email', email)
			handleCheckAccount(email)
		}
	}, [])

	/** Clear temporary user if email is removed */
	useEffect(() => {
		if (values.email == '' && formState.isDirty) {
			dispatch(resetTemporaryUser())
		}
	}, [values.email])

	/** Update form location if there is a location defined for the user */
	useEffect(() => setValue('location', userLocation), [userLocation])

	const [emailAccount, setEmailAccount] = useState(false)
	useEffect(() => {
		if (values.email != null) setEmailAccount(true)
	}, [values.email])

	return (
		<chakra.form w="full" onSubmit={handleSubmit(onSubmit)}>
			<Box w="full">
				<VStack spacing="1rem" mb="2rem">
					<SimpleGrid columns={[2]} spacing="1rem" w="full">
						<Input control={control} errors={errors} name="firstName" placeholder="Your first name" />
						<Input control={control} errors={errors} name="lastName" placeholder="Your last name" />
					</SimpleGrid>

					<SimpleEmail control={control} errors={errors} name="email" placeholder="Email" />

					{emailAccount && (
						<>
							<Password
								control={control}
								errors={errors}
								name="password"
								placeholder="Password"
								includestrength
							/>

							{/* <Checkbox
							mb="1rem"
							control={control}
							errors={errors}
							name="notifications[promos]"
							// label="Notifications"
							description={<>Subscribe to get special offers and updates.</>}
						/> */}

							<Button type="submit" variant="solid" minW="150px" isLoading={isSubmitting}>
								<span>
									{account && account.isActive === false
										? 'Complete Account'
										: temporaryEvent
										? 'Create Account'
										: 'Sign Up'}
								</span>
							</Button>
						</>
					)}
				</VStack>
			</Box>

			<VStack w="full" justify="center">
				<Text display="block" fontSize="0.75rem">
					By creating an account, you agree to our{' '}
					<Link
						color="link"
						href="https://www.vidday.com/terms-of-use"
						target="_blank"
						alt="Visit our Terms of Use page">
						Terms of Use
					</Link>{' '}
					and{' '}
					<Link
						color="link"
						href="https://www.vidday.com/privacy-policy"
						target="_blank"
						alt="Visit our Privacy Policy page">
						Privacy Policy
					</Link>
					.
				</Text>
			</VStack>
		</chakra.form>
	)
}

export default RegisterForm

// import React, { useEffect, useState } from 'react'
// import { chakra, Box, Button, HStack, VStack, Text, Link, SimpleGrid } from '@chakra-ui/react'
// import SimpleEmail from '../../../../molecules/controls/InputEmail/SimpleEmail'
// import Password from '../../../../molecules/controls/Password'
// import Input from '../../../../molecules/controls/Input'
// import Checkbox from '../../../../molecules/controls/Checkbox'
// import { useFormContext } from 'react-hook-form'
// import useTemporaryUser from '../../../../../hooks/useTemporaryUser'
// import { useDispatch } from 'react-redux'
// import { resetTemporaryUser } from '../../../../../../api/app/auth'
// import { useSearchParams } from 'react-router-dom'
// import useAuthHandlers from '../../hooks/useAuthHandlers'
// import useUserLocation from '../../../../../hooks/useUserLocation'
// import useTemporaryEvent from '../../../../../hooks/useTemporaryEvent'
// import { useTemporaryUserContext } from '../../../../../contexts/TemporaryUserProvider'

// const RegisterForm = ({ onSubmit, isSubmitting }) => {
// 	const dispatch = useDispatch()
// 	const userLocation = useUserLocation()
// 	const { handleCheckAccount } = useAuthHandlers()
// 	const temporaryEvent = useTemporaryEvent()

// 	const account = useTemporaryUserContext()
// 	const [search] = useSearchParams()

// 	const { control, handleSubmit, errors, setValue, formState, watch, trigger } = useFormContext()

// 	const {
// 		email,
// 		// , pollingUserData
// 	} = useTemporaryUser()

// 	/** Watch values of the form */
// 	const values = watch()

// 	/** If we've entered an email address the  the sign in form for an account that does not exist,
// 	 * retrieve the value and save it as default email address */
// 	useEffect(() => {
// 		if (email) setValue('email', String(email).toLowerCase())
// 	}, [])

// 	/** If no email available for temporary user, and there is one present in search params, use
// 	 * it to populate email field in the form */
// 	useEffect(() => {
// 		if (search.has('email')) {
// 			const urlEncodedEmail = search.get('email')
// 			const email = decodeURIComponent(urlEncodedEmail).toLowerCase()
// 			setValue('email', email)
// 			handleCheckAccount(email)
// 		}
// 	}, [])

// 	/** Clear temporary user if email is removed */
// 	useEffect(() => {
// 		if (values.email == '' && formState.isDirty) {
// 			dispatch(resetTemporaryUser())
// 		}
// 	}, [values.email])

// 	/** Update form location if there is a location defined for the user */
// 	useEffect(() => setValue('location', userLocation), [userLocation])

// 	const [emailAccount, setEmailAccount] = useState(false)
// 	useEffect(() => {
// 		if (values.email != '') setEmailAccount(true)
// 	}, [values.email])

// 	console.log('Errors', errors)

// 	return (
// 		<chakra.form
// 			w="full"
// 			onSubmit={handleSubmit(() => {
// 				console.log('Handle it fuck')
// 				onSubmit()
// 			})}>
// 			<Box w="full">
// 				<VStack spacing="1rem" mb="1rem">
// 					<SimpleGrid columns={[2]} spacing="1rem" w="full">
// 						<Input control={control} errors={errors} name="firstName" placeholder="Your first name" />
// 						<Input control={control} errors={errors} name="lastName" placeholder="Your last name" />
// 					</SimpleGrid>

// 					<SimpleEmail
// 						onFocus={() => {
// 							setEmailAccount(true)
// 						}}
// 						control={control}
// 						errors={errors}
// 						name="email"
// 						placeholder="Email"
// 					/>

// 					{emailAccount && (
// 						<>
// 							<Password
// 								control={control}
// 								errors={errors}
// 								name="password"
// 								placeholder="Create a password"
// 								includestrength
// 							/>

// 							{/* <Checkbox
// 								control={control}
// 								errors={errors}
// 								name="notifications[promos]"
// 								// label="Notifications"
// 								description={<>Subscribe to get special offers and updates.</>}
// 							/> */}

// 							<Button
// 								onClick={async () => {
// 									const result = await trigger()
// 								}}
// 								variant="solid"
// 								minW="150px"
// 								isLoading={isSubmitting}>
// 								{account && account.isActive === false
// 									? 'Complete Account'
// 									: temporaryEvent
// 									? 'Create Account'
// 									: 'Sign Up'}
// 							</Button>
// 						</>
// 					)}
// 				</VStack>
// 			</Box>

// 			<VStack w="full" justify="center">
// 				<Text display="block" align="center" fontSize="0.75rem">
// 					By creating an account, you agree to our{' '}
// 					<Link
// 						color="link"
// 						href="https://www.vidday.com/terms-of-use"
// 						target="_blank"
// 						alt="Visit our Terms of Use page">
// 						Terms of Use
// 					</Link>{' '}
// 					and{' '}
// 					<Link
// 						color="link"
// 						href="https://www.vidday.com/privacy-policy"
// 						target="_blank"
// 						alt="Visit our Privacy Policy page">
// 						Privacy Policy
// 					</Link>
// 					.
// 				</Text>
// 			</VStack>
// 		</chakra.form>
// 	)
// }

// export default RegisterForm
