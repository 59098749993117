import React, { useCallback, useEffect, useState } from 'react'
import {
	AlertIcon,
	AlertTitle,
	AlertDescription,
	Alert,
	Container,
	Text,
	SimpleGrid,
	Flex,
	Link,
	VStack,
	Box,
	Modal,
	ModalOverlay,
	ModalContent,
} from '@chakra-ui/react'
import Divider from '../../../atoms/Divider'
import Card from '../../../atoms/Card'
import { Helmet } from 'react-helmet'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import GoogleButton from '../atoms/GoogleButton'
import LoginForm from './Form'
import Header from './Header'
import useTemporaryUser from '../../../../hooks/useTemporaryUser'
import useScrollTopOnMount from '../../../../hooks/useScrollTopOnMount'
import useTemporaryEvent from '../../../../hooks/useTemporaryEvent'
import { schemaEmailAndPassword } from './schema'
import useAuthHandlers from '../hooks/useAuthHandlers'
import { Link as RouterLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { loginUser, loginUserSuccess } from '../../../molecules/controls/InputEmail/utils'
import { shortenEmail } from '../utils/shortenEmail'
import Event from '../../Event'
import redirectToNextIfWindow from '../../../../utils/redirectToNextIfWindow'
import AppleButton from '../atoms/AppleButton'

const ConditionallyWrapForm = ({ condition, children }) => {
	if (condition) {
		return (
			<Modal
				onClose={() => {}}
				isOpen={true}
				scrollBehavior="outside"
				isCentered={['false', 'true']}
				size="lg"
				autoFocus={false}
				variant={condition?.occasion == 'memorial' ? null : 'confetti'}>
				<ModalOverlay bg="rgba(0,0,0,.75)" backdropFilter="auto" backdropBlur="0" />
				<ModalContent bg={'white'} mx="1rem">
					{children}
				</ModalContent>
			</Modal>
		)
	} else {
		return (
			<Container
				// maxW="container.sm"
				pt="2rem"
				variant="main"
				maxW="480px">
				<Card
					px={['1rem', '2rem']}
					py="2rem"
					alignItems="center"
					variant="main"
					mb="2rem"
					textAlign="center"
					mx={['1rem', 0]}
					borderRadius={['xl']}>
					{children}
				</Card>
			</Container>
		)
	}
}

const Login = () => {
	useScrollTopOnMount()
	const dispatch = useDispatch()
	const location = useLocation()
	const navigate = useNavigate()
	const [query] = useSearchParams()
	const temporaryEvent = useTemporaryEvent()

	/** Are we submitting the form? */
	const [isSubmitting, setIsSubmitting] = useState(false)
	/** Hold an error value if the form submission trigger an error outside a field value (ie. 500 code) */
	const [formError, setFormError] = useState(false)
	/** If a user try to sign in but the email doesn't exist, let's suggest to register */
	const [suggestRegistration, setSuggestRegistration] = useState(null)

	const { handleResendVerificationEmail } = useAuthHandlers()
	const { account } = useTemporaryUser()

	/** Local State */
	const [sentIndicator, setSentIndicator] = useState(false)
	const [resetSuccess, setResetSuccess] = useState(false)

	useEffect(() => {
		if (location.hash === '#resetsuccess') {
			setResetSuccess(true)
		}
	}, [])

	/** Form Hook */
	const methods = useForm({
		defaultValues: {
			email: null,
			password: null,
		},
		resolver: yupResolver(schemaEmailAndPassword), //account && account.isRegistered && !account.isActive ? schemaEmail : schema
	})
	const { setError, watch } = methods

	const values = watch()

	const resendEmailVerification = (email) => {
		handleResendVerificationEmail(email)
		setSentIndicator(true)
		setTimeout(() => {
			setSentIndicator(false)
		}, 6000)
	}
	/**
	 * Form Submit callback
	 * */
	const onSubmit = async (data) => {
		setIsSubmitting(true)

		data.email = String(data.email).trim().toLowerCase()
		data.password = String(data.password).trim()

		const res = await loginUser(data)

		setIsSubmitting(false)

		if (res.success == false) {
			// Password is wrong
			if (res.status == 401) {
				setError('password', { type: 'error', message: res.message })
			}
			// Email was not found
			else if (res.status == 404) {
				setError('email', { type: 'error', message: res.message })
				// Let's save the email that we used to suggest registration instead of login
				setSuggestRegistration(data.email)
			}
			// If the account isn't active yet
			else if (res.status == 400 && res.reason == 'ACTIVATION_REQUIRED') {
				// Let's resend an email
				resendEmailVerification(data.email)
			}
			// Network error/missing info
			else {
				// We no longer have a field error,
				// but a form error instead (such as a Network Error).
				setFormError(res.message)
			}
		} else {
			const redirect = location.search || '/dashboard'

			// Proceed with authenticating user and updating redux state...
			loginUserSuccess(res.data, dispatch, redirect, temporaryEvent)
		}
	}

	const buildRedirectUrl = useCallback(() => {
		query.set('email', suggestRegistration)
		return `/auth/register?${query.toString()}`
	}, [query, suggestRegistration])

	return (
		<>
			<Helmet>
				<title>VidDay - Login</title>
			</Helmet>

			{temporaryEvent && <Event event={temporaryEvent} />}

			<ConditionallyWrapForm condition={temporaryEvent}>
				<FormProvider {...methods}>
					<Header textAlign="center" mb="2rem" />

					{!account && (
						<>
							<SimpleGrid columns={[1, 1]} spacing="1rem" maxW={['300px', 'full']} mx="auto">
								<GoogleButton label="Sign in" />
								<AppleButton label="Sign in" />
								{/* <FacebookButton label="Sign Up" /> */}
							</SimpleGrid>

							<Box w="full" my="2rem" pos="relative">
								<Divider text="Or" borderStyle="dashed" borderColor="gray.300" />
							</Box>
						</>
					)}

					{sentIndicator && (
						<Alert status="success" borderRadius="lg" mb="1rem" variant="solid">
							<AlertIcon />
							<AlertTitle>Sent!</AlertTitle>
							<AlertDescription>Please check your email.</AlertDescription>
						</Alert>
					)}

					{resetSuccess && (
						<Alert status="success" borderRadius="lg" mb="1rem" variant="solid">
							<AlertIcon />
							<VStack alignItems="flex-start" spacing="0">
								<AlertTitle>Done!</AlertTitle>
								<AlertDescription>Your password has been reset.</AlertDescription>
							</VStack>
						</Alert>
					)}

					{formError && (
						<Alert status="error" borderRadius="lg" mb="1rem" variant="solid">
							<AlertIcon />
							<AlertTitle>Error</AlertTitle>
							<AlertDescription>{formError}</AlertDescription>
						</Alert>
					)}

					{suggestRegistration && suggestRegistration == values.email && (
						<Alert
							status="info"
							borderRadius="lg"
							mb="1rem"
							variant="solid"
							justifyContent="center"
							px="0.25rem"
							textAlign="center"
							onClick={
								() => redirectToNextIfWindow(buildRedirectUrl())
								//  navigate(buildRedirectUrl())
							}>
							<AlertDescription>
								Click here to register with:
								<br /> <strong>{shortenEmail(suggestRegistration)}</strong>
							</AlertDescription>
						</Alert>
					)}

					<LoginForm onSubmit={onSubmit} isSubmitting={isSubmitting} mb="2rem" />

					<Flex w="full" justify="center">
						<Text>
							Don't have an account?{' '}
							<Link as="a" href="/auth/register" variant="link">
								Register Now
							</Link>
						</Text>
					</Flex>
				</FormProvider>
			</ConditionallyWrapForm>
		</>
	)
}

export default Login
