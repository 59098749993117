import React, { memo, useCallback, useState } from 'react'
import {
	Link,
	Box,
	Text,
	SimpleGrid,
	Flex,
	Alert,
	AlertIcon,
	AlertTitle,
	AlertDescription,
	Modal,
	ModalContent,
	ModalOverlay,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FormProvider } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import useUserLocation from '../../../../hooks/useUserLocation'
import GoogleButton from '../atoms/GoogleButton'
import RegisterForm from './Form'
import Header from './Header'
import useTemporaryUser from '../../../../hooks/useTemporaryUser'
import userSchema from './schema'
import useTemporaryEvent from '../../../../hooks/useTemporaryEvent'
import useScrollTopOnMount from '../../../../hooks/useScrollTopOnMount'
import { Link as RouterLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { createEvent, registerUser, verifyEmail } from '../../../molecules/controls/InputEmail/utils'
import { REGISTER_SUCCESS } from '../../../../../api/app/constants'
import { shortenEmail } from '../utils/shortenEmail'
import Event from '../../Event'
import Divider from '../../../atoms/Divider'
import redirectToNextIfWindow from '../../../../utils/redirectToNextIfWindow'
import AppleButton from '../atoms/AppleButton'

const Register = () => {
	useScrollTopOnMount()
	const dispatch = useDispatch()
	const location = useLocation()
	const navigate = useNavigate()
	const [query] = useSearchParams()
	const userLocation = useUserLocation()
	const { account } = useTemporaryUser()
	const temporaryEvent = useTemporaryEvent()

	/** Are we submitting the form? */
	const [isSubmitting, setIsSubmitting] = useState(false)
	/** Hold an error value if the form submission trigger an error outside a field value (ie. 500 code) */
	const [formError, setFormError] = useState(false)
	/** If a user try to register but the email already exist, let's suggest to login */
	const [suggestLogin, setSuggestLogin] = useState(null)

	// /** Form Hook */
	const methods = useForm({
		defaultValues: {
			email: null,
			firstName: null,
			lastName: null,
			location: null,
			password: null,
		},
		// mode: 'onBlur',
		resolver: yupResolver(userSchema),
	})

	const { setError, watch } = methods

	const values = watch()

	/** Form Submit callback */
	const onSubmit = async (data) => {
		setIsSubmitting(true)

		data.email = String(data.email).trim().toLowerCase()
		data.password = String(data.password).trim()

		/** First, let's verify the email address is valid */
		const verify = await verifyEmail(data.email)

		/**
		 * If the email is not valid, let the user know.
		 * */
		if (!verify.isValid) {
			setError('email', {
				type: 'error',
				message: 'Invalid email, please check your entry.',
			})
			setIsSubmitting(false)
		} else {
			/**
			 * If the email is valid, we can proceed with the registration
			 * */
			/** Attached user location to the new registration */
			data.location = userLocation
			/** known email has an account but lets ask to verify this time around */
			data.sendEmail = true
			/** Sign them up */
			data.notifications = {
				promos: true,
			}
			/** Request to register the user */
			const registration = await registerUser(data)

			setIsSubmitting(false)

			/** We got an error during registration */
			if (!registration.success) {
				/** If user is already registered, let the user know. */
				if (registration.reason == 'ACCOUNT_EXISTS') {
					setError('email', { type: 'error', message: registration.message })
					// Let's save the email that we used to suggest log in instead of registering
					setSuggestLogin(data.email)
				}
				// Network error/missing info
				else {
					setFormError(registration.message)
				}
			} else {
				/**
				 * Registration was processed successfully,
				 * let's update our user
				 *  */
				const user = registration.data.data

				// Let's save our new user temporarely for compatibility
				dispatch({ type: REGISTER_SUCCESS, payload: registration.data })

				// prepare activation url
				let navigateTo = `/auth/login?activation_required=${registration.data.activationRequired}`

				/**
				 *	If we have a temporary Event, let's post it
				 */
				if (temporaryEvent) {
					// Pass new user id for the event
					const temp = { ...temporaryEvent, creatorId: user.id }
					// Request new event creation
					const eventCreation = await createEvent(temp)
					// If that was successful, clean up store
					if (eventCreation.success) {
						const event = eventCreation.data.data
						// append new events url to activation url
						navigateTo = `${navigateTo}&next=/event/${event.uuid}`
					}
				}

				/** Success! Redirect user to login form */
				navigate(navigateTo)
			}
		}
	}

	const buildRedirectUrl = useCallback(() => {
		query.set('email', suggestLogin)
		return `/auth/login?${query.toString()}`
	}, [query, suggestLogin])

	return (
		<>
			<Helmet>
				<title>VidDay - Create an account</title>
			</Helmet>

			{temporaryEvent && <Event event={temporaryEvent} />}

			<Modal
				onClose={() => {}}
				isOpen={true}
				scrollBehavior="outside"
				isCentered={['false', 'true']}
				size="lg"
				autoFocus={false}
				variant={temporaryEvent?.occasion == 'memorial' ? null : 'confetti'}>
				<ModalOverlay bg="rgba(0,0,0,.75)" backdropFilter="auto" backdropBlur="0" />
				<ModalContent bg={'white'} mx="1rem">
					<FormProvider {...methods}>
						<Header mb=".5rem" textAlign="center" />

						<Flex w="full" justify="center" mb="2rem">
							<Text>
								Already have an account?{' '}
								<Link as="a" href="/auth/login" variant="link">
									Log In
								</Link>
							</Text>
						</Flex>

						{!account && (
							<>
								<SimpleGrid columns={[1, 1]} spacing="1rem" maxW={['300px', 'full']} mx="auto">
									<GoogleButton label="Sign up" />
									<AppleButton label="Sign up" />
									{/* <FacebookButton label="Sign Up" /> */}
								</SimpleGrid>

								<Box w="full" my="2rem" pos="relative">
									<Divider text="Or" borderStyle="dashed" borderColor="gray.300" />
								</Box>
							</>
						)}

						{formError && (
							<Alert status="error" borderRadius="lg" mb="1rem" variant="solid">
								<AlertIcon />
								<AlertTitle>Error</AlertTitle>
								<AlertDescription>{formError}</AlertDescription>
							</Alert>
						)}

						{suggestLogin && suggestLogin == values.email && (
							<Alert
								status="info"
								borderRadius="lg"
								mb="1rem"
								variant="solid"
								justifyContent="center"
								px="0.25rem"
								textAlign="center"
								onClick={
									() => redirectToNextIfWindow(buildRedirectUrl())
									// navigate(buildRedirectUrl())
								}>
								<AlertDescription>
									Click here to log in with:
									<br />
									<strong>{shortenEmail(suggestLogin)}</strong>
								</AlertDescription>
							</Alert>
						)}

						<RegisterForm onSubmit={onSubmit} isSubmitting={isSubmitting} />
					</FormProvider>
				</ModalContent>
			</Modal>
		</>
	)
}

export default Register
